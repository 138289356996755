var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height d-flex justify-space-between flex-column mt-4 align-center"},[_c('div',{staticClass:" full-width",staticStyle:{"max-width":"1400px"}},[_c('div',{staticClass:"text-h5 mb-sm-4 pa-4 pa-sm-0 ml-3 primary--text d-flex align-center",class:{ 'breadcrumbs-container': this.$vuetify.breakpoint.xsOnly }},[_c('v-breadcrumbs',{staticClass:"pa-0 breadcrumbs",attrs:{"items":_vm.breadcrumb,"large":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"text-h6 pa-0",attrs:{"link":"","to":item.href,"exact":"","disabled":item.disabled}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])]}}])}),(_vm.currentComponent)?_c('span',{staticClass:"text-h6 font-weight-bold px-4 current-component"},[_vm._v(" / "+_vm._s(_vm.currentComponent.description)+" "),(_vm.language)?_c('span',[_vm._v(" ("+_vm._s(_vm.language.toUpperCase())+") ")]):_vm._e()]):_vm._e()],1),(_vm.activeComponent === 'services')?_c('v-card',{staticClass:"elevation-4 pa-10",attrs:{"width":"100%"}},[(_vm.services.length > 1)?[_c('v-card-title',[_c('div',{staticClass:"full-width text-center"},[_vm._v(" "+_vm._s(_vm.$t("orders.headers.select_service"))+" ")])]),_c('v-card-text',[_c('v-row',{staticClass:"py-1 mx-2"},[_c('v-col',{staticClass:"services py-0",attrs:{"cols":"12"}},_vm._l((_vm.services),function(item){return _c('v-hover',{key:item.value,attrs:{"disabled":!_vm.canViewUsaid(item.alias)},on:{"input":_vm.setHover}},[_c('div',{staticClass:"ma-2 pa-2 services__item d-flex align-stretch justify-center flex-column text-center cursor-pointer",class:{
                    activeService:
                      _vm.serviceId === item.id && _vm.canSelectService(item.alias),
                    'services__item--hover': _vm.canSelectService(item.alias),
                    'disabled-service': !_vm.canSelectService(item.alias)
                  },style:({
                    background: item.color,
                    borderColor: item.color,
                    color: _vm.getColor(item)
                  }),on:{"click":function($event){return _vm.selectComponent(item)}}},[_c('div',{staticClass:"d-flex align-center justify-center"},[(item.icon_url)?_c('img',{attrs:{"height":"50","src":item.icon_url,"alt":item.name}}):_vm._e()]),_c('div',{staticClass:"full-width d-flex align-center justify-center"},[_c('div',{staticClass:"line my-2",style:({ background: _vm.getColor(item) })})]),_c('div',{staticClass:"fill-height d-flex align-center justify-center font-weight-bold description"},[_vm._v(" "+_vm._s(item.description)+" ")])])])}),1)],1)],1)]:_vm._e(),(_vm.serviceId)?_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('allocation',{attrs:{"service-type":_vm.serviceId},on:{"input":_vm.setAllocations}})],1):_vm._e(),_c('v-card-text',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex align-center justify-center",class:{ 'flex-column': this.$vuetify.breakpoint.xsOnly }},[_c('language-select',{attrs:{"languages":_vm.items},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('v-btn',{key:_vm.forceKey,staticClass:"ml-xs-0 ml-sm-4",class:{
              'full-width mt-4 ml-0': this.$vuetify.breakpoint.xsOnly
            },attrs:{"depressed":"","color":"primary","disabled":_vm.disabled()},on:{"click":function($event){return _vm.showOrder()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("orders.buttons.create_order")))]),_c('v-icon',{staticClass:"ml-3"},[_vm._v("mdi-chevron-double-right")])],1)],1),(_vm.serviceId === 1)?_c('div',{staticClass:"d-flex align-center justify-center pt-7 flex-column"},[_c('img',{staticStyle:{"height":"70px","object-fit":"contain"},attrs:{"alt":"berdLogo","src":_vm.berdLogo}})]):_vm._e()]),(
          _vm.showUsaid ||
            _vm.usaidServices.includes(_vm.currentComponent && _vm.currentComponent.alias)
        )?_c('v-card-text',{staticClass:"mt-8"},[_c('v-divider',{class:{ 'mb-8': this.$vuetify.breakpoint.mdAndUp }}),_c('div',{staticClass:"d-flex full-width align-center mt-sm-2 mt-xl-5 usaid-info",class:{ 'flex-column': this.$vuetify.breakpoint.smAndDown }},[_c('img',{attrs:{"src":_vm.usaidLogo,"width":"300","alt":"usaid"}}),_c('div',[(this.$vuetify.breakpoint.smAndDown)?_c('v-divider',{staticClass:"mb-8"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("orders.usaid_text"))+" ")],1)])],1):_vm._e()],2):_vm._e(),(_vm.activeComponent === 'component')?_c('transition',{attrs:{"name":"fade"}},[_c(_vm.currentComponent.alias,{tag:"component",attrs:{"service-id":_vm.serviceId,"allocations":_vm.allocations,"language":_vm.language,"client":_vm.client,"show-regulation":_vm.userOrderCounts[_vm.currentComponent.alias] === 0}})],1):_vm._e()],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }